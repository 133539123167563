/* @flow */

import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import useRouteWithUrlParamsString from 'src/shared/app/authentication/hook/useRouteWithUrlParamsString';
import messages from 'src/shared/app/authentication/l10n/authenticationL10n';
import { signupRoute } from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';

import styles from './AuthenticationBase.style';

const useStyles = createUseThemeStyles(styles);

const AuthenticationSignupButton = (): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */

  const { isSignupEnabled } = useFeatureDecisions();
  const intl = useIntl();

  const signupPath = useRouteWithUrlParamsString(signupRoute);

  /*
   |----------------------------------------------------------------------------
   | Effects
   |----------------------------------------------------------------------------
   */

  const classes = useStyles();

  if (!isSignupEnabled) return null;

  return (
    <div className={classes.signup}>
      <p>
        {intl.formatMessage(messages.dontHaveAccount)}{' '}
        <Link to={signupPath} variant="secondary">
          {intl.formatMessage(messages.signUp)}
        </Link>
      </p>
    </div>
  );
};

export default memo<{}>(AuthenticationSignupButton);
