/* @flow */

import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  title: {
    fontSize: rem(20, baseFontSize),
    marginTop: 0,
    marginBottom: 0,
    color: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  tooltipIcon: {
    width: 25,
    height: 25,
    borderRadius: '50%',
    borderColor: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    color: ({ theme }: ThemeFunction<{}>) => theme.colorBase,
    backgroundColor: ({ theme }: ThemeFunction<{}>) => theme.accentColor,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      fontSize: rem(16, baseFontSize),
    },
    '&:focus': {
      outlineOffset: 2,
      outlineStyle: 'solid',
      outlineWidth: 1,
    },
  },
};
