/* @flow */

import type { BraindateEvent } from '@braindate/domain/lib/event/type';
import { getMembershipId } from '@braindate/domain/lib/membership/util';
import { getUserId } from '@braindate/domain/lib/user/util';

import { apiRoot } from 'src/shared/app/base/api/apiRoot';
import { isErrorReportingEnabled } from 'src/shared/app/base/selector/appEnvSelectors';
import {
  loginEndpoint,
  loginWithDoubleDutchEndpoint,
  loginWithExternalAuthEndpoint,
  userResetPasswordEndpoint,
  userSendPasswordLinkEndpoint,
  userSetPasswordEndpoint,
  userValidateEmailEndpoint,
} from 'src/shared/app/base/settings/endpointSettings';
import { parseResponse } from 'src/shared/app/base/util/ajaxUtils';
import { registerSentryUserFromBrowser } from 'src/shared/app/base/util/sentryUtils';
import { saveTokenInCookie } from 'src/shared/app/base/util/tokenUtils';
import type { GetState } from 'src/shared/core/type/reduxTypes';
import { isClient } from 'src/shared/core/util/ssrUtil';
import { getEventSafely } from 'src/shared/domain/event/selector/eventSelectors';

import * as types from './authenticationActionTypes';

import type { Dispatch } from 'redux';

/*
|------------------------------------------------------------------------------
| AUTHENTICATION TOKEN
|------------------------------------------------------------------------------
*/

export function setAuthenticationToken(
  token: string,
  tokenPassedAsQueryParam: ?boolean,
  event: BraindateEvent,
  tokenBearer: string | null,
): Object {
  return {
    type: types.SET_AUTHENTICATION_TOKEN,
    token,
    tokenPassedAsQueryParam: tokenPassedAsQueryParam || false,
    event,
    tokenBearer,
  };
}

export function unsetAuthenticationToken(): Object {
  return {
    type: types.UNSET_AUTHENTICATION_TOKEN,
  };
}

export function setLoggedInWithBOS(): Object {
  return {
    type: types.SET_LOGGED_IN_WITH_BOS,
  };
}

/*
|------------------------------------------------------------------------------
| AUTHENTICATION EMAIL
|------------------------------------------------------------------------------
*/

export function setAuthenticationEmail(email: string): Object {
  return {
    type: types.SET_AUTHENTICATION_EMAIL,
    email,
  };
}

export function unsetAuthenticationEmail(): Object {
  return {
    type: types.UNSET_AUTHENTICATION_EMAIL,
  };
}

/*
|------------------------------------------------------------------------------
| AUTHENTICATION UID
|------------------------------------------------------------------------------
*/

export function setAuthenticationUid(uid: string): Object {
  return {
    type: types.SET_AUTHENTICATION_UID,
    uid,
  };
}

export function unsetAuthenticationUid(): Object {
  return {
    type: types.UNSET_AUTHENTICATION_UID,
  };
}

/*
|------------------------------------------------------------------------------
| AUTHENTICATION CID
|------------------------------------------------------------------------------
*/

export function setAuthenticationCid(cid: string): Object {
  return {
    type: types.SET_AUTHENTICATION_CID,
    cid,
  };
}

export function unsetAuthenticationCid(): Object {
  return {
    type: types.UNSET_AUTHENTICATION_CID,
  };
}

/*
|------------------------------------------------------------------------------
| AUTHENTICATION RESET TOKEN
|------------------------------------------------------------------------------
*/

export function setAuthenticationResetToken(token: string): Object {
  return {
    type: types.SET_AUTHENTICATION_RESET_TOKEN,
    token,
  };
}

export function unsetAuthenticationResetToken(): Object {
  return {
    type: types.UNSET_AUTHENTICATION_RESET_TOKEN,
  };
}

/*
|------------------------------------------------------------------------------
| VALIDATE EMAIL
|------------------------------------------------------------------------------
*/

export function validateEmail(email: string, eventId: number): any {
  return (dispatch: Dispatch<any>, _: GetState, { post }: Object) => {
    dispatch(validateEmailRequest());

    return post(userValidateEmailEndpoint(), {
      username: email,
      event_id: eventId,
    })
      .then(parseResponse)
      .then((json) => {
        const { password_set: isPasswordSet } = json;

        dispatch(validateEmailSuccess(email, isPasswordSet));

        return isPasswordSet;
      })
      .catch((exception) => {
        dispatch(validateEmailFailure(exception));

        throw exception;
      });
  };
}

export function validateEmailRequest(): Object {
  return {
    type: types.VALIDATE_EMAIL_REQUEST,
  };
}

export function validateEmailSuccess(
  email: string,
  isPasswordSet: boolean,
): Object {
  return {
    type: types.VALIDATE_EMAIL_SUCCESS,
    email,
    isPasswordSet,
  };
}

export function validateEmailFailure(exception: Error): Object {
  return {
    type: types.VALIDATE_EMAIL_FAILURE,
    exception,
  };
}

/*
|------------------------------------------------------------------------------
| CREATE PASSWORD
|------------------------------------------------------------------------------
*/

export function createPassword(
  email: string,
  password: string,
  eventId: number,
): any {
  return async (dispatch: Dispatch<any>, getState, { post }: Object) => {
    dispatch(createPasswordRequest());

    try {
      const response = await post(userSetPasswordEndpoint(), {
        username: email,
        password,
        event_id: eventId,
      });
      const json = await parseResponse(response);

      const { user, userId, membershipId, membership, token } =
        await parseAuthResponse(
          dispatch,
          json,
          true,
          isErrorReportingEnabled(getState()),
          getEventSafely(getState()),
        );

      dispatch(
        createPasswordSuccess(userId, membershipId, token, membership.event),
      );

      return { user, membership };
    } catch (exception) {
      dispatch(createPasswordFailure(exception));
      throw exception;
    }
  };
}

export function createPasswordRequest(): Object {
  return {
    type: types.CREATE_PASSWORD_REQUEST,
  };
}

export function createPasswordSuccess(
  userId: number,
  membershipId: number,
  token: string,
  event: BraindateEvent,
): Object {
  return {
    type: types.CREATE_PASSWORD_SUCCESS,
    userId,
    membershipId,
    token,
    event,
  };
}

export function createPasswordFailure(exception: Error): Object {
  return {
    type: types.CREATE_PASSWORD_FAILURE,
    exception,
  };
}

/*
|------------------------------------------------------------------------------
| SEND PASSWORD LINK
|------------------------------------------------------------------------------
*/

export function sendPasswordLink(email: string, eventId: number): any {
  return (dispatch: any, _: GetState, { post }: Object) => {
    dispatch(sendPasswordLinkRequest());

    return post(userSendPasswordLinkEndpoint(), {
      username: email,
      event_id: eventId,
    })
      .then(parseResponse)
      .then(() => dispatch(sendPasswordLinkSuccess(email)))
      .catch((exception) => {
        dispatch(sendPasswordLinkFailure(exception));

        throw exception;
      });
  };
}

export function sendPasswordLinkRequest(): Object {
  return {
    type: types.SEND_PASSWORD_LINK_REQUEST,
  };
}

export function sendPasswordLinkSuccess(email: string): Object {
  return {
    type: types.SEND_PASSWORD_LINK_SUCCESS,
    email,
  };
}

export function sendPasswordLinkFailure(exception: Error): Object {
  return {
    type: types.SEND_PASSWORD_LINK_FAILURE,
    exception,
  };
}

export function resetPasswordLinkSent(): Object {
  return {
    type: types.RESET_PASSWORD_LINK_SENT,
  };
}

/*
|------------------------------------------------------------------------------
| VALIDATE PASSWORD LINK
|------------------------------------------------------------------------------
*/

export function validatePasswordLink(resetToken: string, uid: string): any {
  return (dispatch: any, _: GetState, { post }: Object) => {
    dispatch(validatePasswordLinkRequest());

    return post(userResetPasswordEndpoint(), {
      reset_token: resetToken,
      uid,
    })
      .then(parseResponse)
      .then(() => dispatch(validatePasswordLinkSuccess()))
      .catch((exception) => {
        dispatch(validatePasswordLinkFailure(exception));

        throw exception;
      });
  };
}

export function validatePasswordLinkRequest(): Object {
  return {
    type: types.VALIDATE_PASSWORD_LINK_REQUEST,
  };
}

export function validatePasswordLinkSuccess(): Object {
  return {
    type: types.VALIDATE_PASSWORD_LINK_SUCCESS,
  };
}

export function validatePasswordLinkFailure(exception: Error): Object {
  return {
    type: types.VALIDATE_PASSWORD_LINK_FAILURE,
    exception,
  };
}

/*
|------------------------------------------------------------------------------
| RESET PASSWORD
|------------------------------------------------------------------------------
*/

export function resetPassword(
  resetToken: string,
  uid: string,
  password: string,
  eventId: number,
): any {
  return async (dispatch: any, getState, { post }: Object) => {
    dispatch(resetPasswordRequest());

    try {
      const response = await post(userResetPasswordEndpoint(), {
        reset_token: resetToken,
        uid,
        password,
        event_id: eventId,
      });
      const json = await parseResponse(response);

      const { userId, membershipId, token } = await parseAuthResponse(
        dispatch,
        json,
        true,
        isErrorReportingEnabled(getState()),
        getEventSafely(getState()),
      );

      dispatch(resetPasswordSuccess(userId, membershipId, token));

      return json;
    } catch (exception) {
      dispatch(resetPasswordFailure(exception));

      throw exception;
    }
  };
}

export function resetPasswordRequest(): Object {
  return {
    type: types.RESET_PASSWORD_REQUEST,
  };
}

export function resetPasswordSuccess(
  userId: number,
  membershipId: number,
  token: string,
): Object {
  return {
    type: types.RESET_PASSWORD_SUCCESS,
    userId,
    membershipId,
    token,
  };
}

export function resetPasswordFailure(exception: Error): Object {
  return {
    type: types.RESET_PASSWORD_FAILURE,
    exception,
  };
}

/*
|------------------------------------------------------------------------------
| LOGIN
|------------------------------------------------------------------------------
*/

export function login(email: string, password: string, eventId: number): any {
  return async (dispatch: any, getState, { post }: Object) => {
    dispatch(loginRequest());

    try {
      const response = await post(loginEndpoint(), {
        username: email,
        password,
        event_id: eventId,
      });
      const json = await parseResponse(response);

      const { user, userId, membershipId, membership, token } =
        await parseAuthResponse(
          dispatch,
          json,
          true,
          isErrorReportingEnabled(getState()),
          getEventSafely(getState()),
        );

      dispatch(loginSuccess(userId, membershipId, token, membership.event));

      return { user, membership };
    } catch (exception) {
      dispatch(loginFailure(exception));

      throw exception;
    }
  };
}

export function loginRequest(): Object {
  return {
    type: types.LOGIN_REQUEST,
  };
}

export function loginSuccess(
  userId: number,
  membershipId: number,
  token: string,
  event: Event,
): Object {
  return {
    type: types.LOGIN_SUCCESS,
    userId,
    membershipId,
    token,
    event,
  };
}

export function loginFailure(exception: Error): Object {
  return {
    type: types.LOGIN_FAILURE,
    exception,
  };
}

/*
|------------------------------------------------------------------------------
| DOUBLE DUTCH
|------------------------------------------------------------------------------
*/

export function loginWithDoubleDutch(
  eventId: number,
  header: string,
  url: string,
): any {
  return async (dispatch: any, getState, { post }: Object) => {
    const response = await post(loginWithDoubleDutchEndpoint(), {
      event: eventId,
      auth_header: header,
      signed_url: url,
    });
    const json = await parseResponse(response);

    const { userId, membershipId, membership, token } = await parseAuthResponse(
      dispatch,
      json,
      true,
      isErrorReportingEnabled(getState()),
      getEventSafely(getState()),
    );

    dispatch(loginSuccess(userId, membershipId, token, membership.event));

    return json;
  };
}

/*
|------------------------------------------------------------------------------
| EXTERNAL AUTH
|------------------------------------------------------------------------------
*/

export function loginWithExternalAuth(params: Object): any {
  return async (dispatch: any, getState, { post }: Object) => {
    const response = await post(loginWithExternalAuthEndpoint(), params);
    const json = await parseResponse(response);

    const { userId, membershipId, membership, token } = await parseAuthResponse(
      dispatch,
      json,
      true,
      isErrorReportingEnabled(getState()),
      getEventSafely(getState()),
    );

    dispatch(loginSuccess(userId, membershipId, token, membership.event));

    return json;
  };
}

/*
|------------------------------------------------------------------------------
| URL
|------------------------------------------------------------------------------
*/

export function saveInitialUrl(url: string) {
  return {
    type: types.SAVE_INITIAL_URL,
    url,
  };
}

export function resetInitialUrl() {
  return {
    type: types.RESET_INITIAL_URL,
  };
}

/*
|------------------------------------------------------------------------------
| PRIVATE FUNCTIONS
|------------------------------------------------------------------------------
*/

export async function parseAuthResponse(
  dispatch: Dispatch<any>,
  json: Object,
  secure?: boolean,
  errorReportingEnabled?: boolean,
  event: BraindateEvent,
): Promise<Object> {
  const { token, user, memberships } = json;

  if (isClient) {
    saveTokenInCookie(token, secure || true, event);

    if (errorReportingEnabled ?? true) {
      registerSentryUserFromBrowser(user);
    }
  }

  const [membership] = memberships;

  await Promise.all([
    dispatch(apiRoot.util.upsertQueryData('getUser', 'me', user)),
    dispatch(
      apiRoot.util.upsertQueryData('getMembership', undefined, membership),
    ),
  ]);

  return {
    user,
    membership,
    userId: getUserId(user),
    membershipId: getMembershipId(membership),
    token,
  };
}
