/* @flow */
import * as Sentry from '@sentry/react';

import { assertError } from '@braindate/util/lib/assert';

import { isErrorReportingEnabled } from 'src/shared/app/base/selector/appEnvSelectors';

export function parseFormErrors(fields: Object): Object {
  const formErrors = {};

  for (const field in fields) {
    const fieldErrors = fields[field];

    if (typeof fieldErrors === 'object' && !Array.isArray(fieldErrors)) {
      for (const field2 in fieldErrors) {
        const fieldErrors2 = fieldErrors[field2];
        const errors = Array.isArray(fieldErrors2)
          ? fieldErrors2.join(' ')
          : fieldErrors2;

        // if (field2 in fieldMapping) {
        //   formErrors[fieldMapping[field2]] = errors;
        // } else
        if (field2 === 'non_field_errors') {
          formErrors.root = errors;
        } else {
          formErrors[field2] = errors;
        }
      }
    } else {
      const errors = Array.isArray(fieldErrors)
        ? fieldErrors.join(' ')
        : fieldErrors;

      // if (field in fieldMapping) {
      //   formErrors[fieldMapping[field]] = errors;
      // } else
      if (field === 'non_field_errors') {
        formErrors.root = errors;
      } else {
        formErrors[field] = errors;
      }
    }
  }

  return formErrors;
}

export function throwFormikSubmissionError(exception: Error): void {
  assertError(exception, 'exception');

  // $FlowIssue
  const { json: fields } = exception;

  const formErrors = parseFormErrors(fields);

  if (!fields || typeof fields !== 'object') {
    if (isErrorReportingEnabled) {
      Sentry.captureException(exception);
    }
    // eslint-disable-next-line no-console
    console.error(exception);
  }

  if (Object.keys(formErrors).length === 0) {
    throw new Error('There has been an unknown error. We sincerely apologize.');
  }

  const e = new Error();

  // $FlowIssue
  e.errors = formErrors;

  throw e;
}
