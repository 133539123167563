/* @flow */

import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    color: ({ theme }: ThemeFunction<{}>) => theme.textColor,

    fontSize: rem(12, baseFontSize),
    fontStyle: 'italic',
    lineHeight: 19 / 12,
    WebkitFontSmoothing: 'antialiased',
  },
};
